import { Component, OnInit } from '@angular/core';
import { DepartmentService } from '../../../services/department.service';
import { Department } from 'src/app/models/department';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss']
})
export class AssociationsComponent implements OnInit {
  list: Department;
  photoUrl: string = environment.serverUrl;

  constructor(private departmentService: DepartmentService) { }

  ngOnInit(): void {
    this.departmentService.getAllDepartmentsById(10).subscribe((data: any) => {
      this.list = data.data.filter(department => department.isActive === true && department.isDeleted === false).sort((a, b) => b.title.localeCompare(b.tştle))

    })
  }

}
