import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventService } from '../../../services/event.service';

@Component({
  selector: 'app-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss']
})
export class EventsPageComponent {
  skip: number = 0;
  pageSize: number = 12;
  totalCount: number = 0;
  search: string = "";
  events: any
  photoUrl: string = environment.serverUrl
  startDate = '1.01.0001%2000:00:00'
  endDate = '1.01.0001%2000:00:00'

  @ViewChild(MatPaginator) paginator: MatPaginator;
  private searchSubject = new Subject<any>();

  constructor(private eventService: EventService) {
    this.searchSubject
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.skip = 0;
        this.pageSize = 12;
        this.totalCount = 0;
        this.paginator.pageIndex = 0;
        this.getAll();

      });
  }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.eventService.getAllEventsResource(this.search, this.startDate, this.endDate, this.skip, this.pageSize).subscribe((data: any) => {
      this.events = data.data.filter(data => data.isActive === true && data.isDeleted === false).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
      this.totalCount = data.totalCount;
    })

  }

  onPageChange(e) {
    this.skip = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAll();

  }

  onChange(e) {
    this.search = e.target.value ? e.target.value : ''
    this.searchSubject.next(this.search);
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.endDate = '1.01.0001%2000:00:00';
    this.startDate = '1.01.0001%2000:00:00'
    const startDateValue = dateRangeStart.value;
    const endDateValue = dateRangeEnd.value;

    if (startDateValue != '') {
      const startDateParts = startDateValue.split('.');
      const startDate = startDateParts[2] + '.' + startDateParts[1] + '.' + startDateParts[0];
      this.startDate = startDate;
    }
    if (endDateValue != '') {
      const endDateParts = endDateValue.split('.');
      const endDate = endDateParts[2] + '.' + endDateParts[1] + '.' + endDateParts[0];
      this.endDate = endDate;
    }

    if (this.startDate != '' && this.endDate != '') {
      this.getAll();
    }


  }

}
