import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Statistic } from 'src/app/models/statistic';
import { StatisticService } from 'src/app/services/statistic.service';


@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent {
  statistic: Statistic[] = [];
  lang:string='';

  constructor(private statisticService: StatisticService) { }

  ngOnInit() {
    this.statisticService.getAllInfo().subscribe((info: Statistic[]) => {
      this.statistic = info.filter(statistic => statistic.isActive == true);
    })
    this.lang=localStorage.getItem('language');
  }

  carouselSlide: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      350: {
        items: 2
      },
      500: {
        items: 3
      },
      600: {
        items: 4
      },
      768: {
        items: 5
      },
      1200: {
        items: 7
      }
    }
  }
}
