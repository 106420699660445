import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PublicationService } from 'src/app/services/publication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homepage-publish',
  templateUrl: './homepage-publish.component.html',
  styleUrls: ['./homepage-publish.component.scss']
})
export class HomepagePublishComponent {
  photoUrl: string = environment.serverUrl
  publication: any[] = [];


  constructor(private publicationService: PublicationService) { }

  ngOnInit() {
    this.publicationService.getAllPublication().subscribe((data: any) => {
      this.publication = data.data;
    })
  }


  carouselPublishSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      350: {
        items: 2
      },
      500: {
        items: 3
      },
      600: {
        items: 4
      },
      768: {
        items: 5
      },
      1200: {
        items: 7
      }
    }
  }
}
