import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentService } from 'src/app/services/content.service';
import { Content } from 'src/app/models/contentPage';

@Component({
  selector: 'app-homepage-news',
  templateUrl: './homepage-news.component.html',
  styleUrls: ['./homepage-news.component.scss']
})
export class HomepageNewsComponent {
  news: Content[] = [];

  constructor(private contentService: ContentService) { }
  ngOnInit() {
    this.contentService.getAllNews().subscribe((data: any) => {
      this.news = data.data.filter(news => news.isActive === true).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).slice(0, 12);

    })
  }

  blogSlides: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 4
      }
    }
  }

}
