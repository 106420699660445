<div class="instructor-area" *ngFor="let formGroup of dynamicForm.controls;" [formGroup]="formGroup">
    <div class="container">
        <div class="container application">
            <div class="tab-wrap application">
                <div class="tab__content">
                    <div class="row top-form">
                        <h3>{{ formGroup.get('title')?.value }}</h3>
                        <img *ngIf="formGroup.get('thumbnail')?.value" [src]="imgUrl+formGroup.get('thumbnail')?.value"
                            alt="logo">
                    </div>
                    <hr>
                    <fieldset class="row col md-12">
                        <div class="col-md-6" *ngFor="let formElement of formGroup.get('childAnswers').controls;"
                            [formGroup]="formElement">
                            <label class="form-label">{{ formElement.get('text')?.value}}
                                <label *ngIf="formElement.value.isRequired" class="text-danger fs-5">*</label>
                            </label>
                            <ng-container [ngSwitch]="formElement.get('type').value">
                                <input *ngSwitchDefault [type]="formElement.get('type').value" class="form-control"
                                    [placeholder]="formElement.get('placeholder').value" formControlName="value"
                                    [ngClass]="(formElement.get('value')?.errors?.['required'] && formElement.get('value')?.touched) ? 'is-invalid':''">



                                <select *ngSwitchCase="'selectbox'" class="form-select" style="cursor:pointer"
                                    formControlName="value"
                                    [ngClass]="formElement.get('value')?.errors?.['required'] && formElement.get('value')?.touched ? 'is-invalid':''">
                                    <option value="" selected disabled>{{ 'SELECT' | translate }}</option>
                                    <option *ngFor="let option of formElement.get('formElementOptions').value"
                                        [value]="option?.value">
                                        {{ option?.value }}
                                    </option>
                                </select>


                                <section *ngSwitchCase="'checkbox'" class="example-section" >
                                    <mat-checkbox class="form-check-label"
                                        *ngFor="let option of formElement.get('formElementOptions').value;let i=index;"
                                        [value]="option.value" [checked]="option.selected"
                                        
                                        (change)="changeValue($event,formElement.get('formElementId').value,formGroup.get('formId').value)"
                                        [ngClass]="formElement.get('value')?.errors?.['required'] && formElement.get('value')?.touched ? 'is-invalid':''">
                                        {{ option?.value }}
                                    </mat-checkbox>
                                    <div *ngIf="formElement.get('formElementOptions').errors?.required && formElement.touched"
                                        class="invalid-feedback">
                                        En az bir tane seçilmelidir.
                                    </div>
                                </section>

                                <textarea *ngSwitchCase="'textarea'" class="form-control"
                                    [placeholder]="formElement.get('placeholder').value" formControlName="value"
                                    [ngClass]="formElement.get('value')?.errors?.['required'] && formElement.get('value')?.touched ? 'is-invalid':''">
                                </textarea>

                                <mat-radio-group *ngSwitchCase="'radio'" aria-labelledby="example-radio-group-label"
                                    class="example-radio-group"
                                    (change)="changeValue($event,formElement.get('formElementId').value,formGroup.get('formId').value)"
                                    [ngClass]="formElement.get('value')?.errors?.['required'] && formElement.get('value')?.touched ? 'is-invalid':''">
                                    <mat-radio-button class="example-radio-button"
                                        *ngFor="let option of formElement.get('formElementOptions').value"
                                        [value]="option.value" [checked]="option.selected">
                                        {{ option.value | translate }}
                                    </mat-radio-button>
                                </mat-radio-group>

                            </ng-container>

                            <div *ngIf="formElement.get('value').invalid && (formElement.get('value').dirty || formElement.get('value').touched)"
                                class="invalid-feedback">
                                <div *ngIf="formElement.get('value').errors?.required">
                                    {{ 'THIS_FIELD_CANNOT_BE_LEFT_EMPTY' | translate }}
                                </div>
                                <div *ngIf="formElement.get('value').errors?.email" class="invalid-feedback">
                                    {{ 'PLEASE_ENTER_A_VALID_EMAIL' | translate }}
                                </div>

                            </div>

                        </div>
                    </fieldset>
                    <!-- <section class="example-section">
                        <mat-checkbox class="example-margin" (change)="checkYes($event,'regulation')"><a
                                [href]="photoUrl+regulation" target="_blank"><u
                                    class="text-danger">{{'REGULATION'|translate}}</u>&nbsp;</a>{{'I_HAVE_READ_AND_ACCEPT'|translate}}
                            <label class="text-danger fs-5">*</label></mat-checkbox>
                    </section> -->

                    <button class="btn btn-primary col-md-12" [disabled]="formGroup.invalid"
                        (click)="saveForm(formGroup)">{{'SAVE' |translate}}</button>

                </div>
            </div>
        </div>
    </div>
</div>
