import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hompage-events',
  templateUrl: './hompage-events.component.html',
  styleUrls: ['./hompage-events.component.scss']
})
export class HompageEventsComponent {
  photoUrl: string = environment.serverUrl
  event: any[] = [];
  eventStatu: any;

  constructor(private eventService: EventService, private appSettingService: AppSettingService) { }

  ngOnInit() {
    this.eventService.getAllEvents().subscribe((data: any) => {
      this.event = data.data.filter(event => event.isActive === true).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).slice(0, 4);
    })


    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.eventStatu = data.find(item => item.key === "etkinlikdurum")?.value;
    })

  }

  carouselEventsSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 5
      }
    }
  }
}
