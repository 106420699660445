
import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppSettingService } from 'src/app/services/appsetting.service';
import { EducationService } from 'src/app/services/education.service';
import { Education } from 'src/app/models/education';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homepage-education',
  templateUrl: './homepage-education.component.html',
  styleUrls: ['./homepage-education.component.scss']
})
export class HomepageEducationComponent {
  photoUrl: string = environment.serverUrl
  education: Education[] = [];
  educationStatu: any;

  constructor(private educationService: EducationService, private appSettingService: AppSettingService) { }

  ngOnInit() {
    this.educationService.getAllEducations().subscribe((data: any) => {
      this.education = data.data.filter(education => education.isActive === true).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).slice(0, 4);
    })

    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.educationStatu = data.find(item => item.key === "egitimdurum")?.value;
    })
  }

  carouselEventsSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 5
      }
    }
  }
}
