<div class="publish-image">
    <a [routerLink]="['/yayin-detay',item.id]" class="d-block">
        <img *ngIf="item?.thumbnail!=null " [src]="photoUrl+item?.thumbnail" alt="image">
        <img *ngIf="item?.thumbnail==null" src="assets/img/DitibLogoBuyuk.png"
            alt="image">
    </a>
</div>
<div class="publish-info">
    <a [routerLink]="['/yayin-detay',item.id]" class="d-block">
        <div class="line-clamp-four">
            <p>{{item?.publicationName}}</p>
            {{item?.writerName}}
        </div>
    </a>
</div>