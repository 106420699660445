<div class="funfacts-style-two ptb-70 mb-70" *ngIf="statistic.length>0">
    <div>
        <div class="row">
            <owl-carousel-o [options]="carouselSlide">
                <ng-template carouselSlide *ngFor="let item of statistic">
                    <div class="single-funfact">
                        <div class="icon">
                            <i [class]='item.icon'></i>
                        </div>
                        <h3><span [countUp]="item.count">00</span></h3>
                        <p *ngIf="lang=='fr'">{{item.descriptionFr}}</p>
                        <p *ngIf="lang=='tr'">{{item.description}}</p>
                    </div>
                </ng-template>

            </owl-carousel-o>
        </div>
    </div>
</div>