import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-memories-card',
  templateUrl: './memories-card.component.html',
  styleUrls: ['./memories-card.component.scss']
})
export class MemoriesCardComponent {
  @Input() item: any;
  photoUrl: string = environment.serverUrl
}
