<div class="single-courses-box mb-30">
    <div class="courses-image">
        <a [href]="['haber-detay/'+item.id]" class="d-block">
            <img *ngIf="item?.thumbnail!='' " [src]="photoUrl+item?.thumbnail"
                alt="image">
            <img *ngIf="item?.thumbnail=='' "
                src="/assets/img/DitibLogoBuyuk.png" alt="image">
        </a>

    </div>
    <div class="courses-content line-clamp-six">
        <a [href]="['haber-detay/'+item.id]">
            <p>{{item?.title}}</p>
        </a>

        <!-- <p class="new-contents " [innerHTML]="item?.description"></p> -->
    </div>

    <div class="courses-box-footer">

        <a [href]="['haber-detay/'+item.id]">
            <button class="view-all-contents-button">
                {{'SEE_MORE' | translate}}

            </button>
        </a>


        <div class="courses-box-date">
            <p>
                {{item?.creationDate | date:'dd.MM.yyyy'}}
            </p>

        </div>
    </div>
</div>